export const environment = {
  production: true,
  apiEndpint: '',
  imgs: {
    imgLogoApp: 'dist/assets/fila-carros-4.svg',
    imgNotFound: 'dist/assets/image-not-found-icon.svg',
    microsoftLogin: 'dist/assets/microsoft_icon.svg',
    bandNavBar: 'dist/assets/colband-logo-preto.png',
  },
  redirectUri: 'https://fila2.colband.net.br/',
  graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
