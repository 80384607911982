<section *ngIf="!loginDisplay">
    <div class="row mt-5 justify-content-center">
        <div class="col-10 col-sm-8 col-md-6 col-lg-4 p-3 bg-white rounded-4 text-center">
            <div class="row justify-content-center">
                <div class="col"><img [src]="imgs.imgLogoApp"></div>
                <div class="col-12 d-grid gap-2">
                    <button class="btn btn-primary" (click)="login()">
                        <span class="d-inline-block col-1"><img class="w-75 img-fluid img-thumbnail" [src]="imgs.microsoftLogin"></span>
                        <span class="h4 align-top ms-1">Entrar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="loginDisplay">
    <div class="row mt-5 justify-content-center">
        <div class="col-10 p-3 bg-white rounded-4 text-center">
            <div class="row">
                <div class="col">
                    <h2>Fila de crianças</h2>
                </div>
            </div>

            <section *ngIf="localSelecionado">
                <div class="row p-2 text-start">
                    <div class="col">
                        <span>{{ localSelecionado.origem }}</span>
                        <a href="#" class="link-primary ms-3" (click)="trocarLocal()">Trocar</a>
                    </div>
                </div>
            </section>

            <section *ngIf="!localSelecionado">
                <div class="row p-2">
                    <div class="col-12 d-grid gap-2" *ngIf="locaisLeituraEntrega.length === 0">
                        Buscando locais...
                    </div>
                    <div class="col-12 d-grid gap-2">
                        <button *ngFor="let local of locaisLeituraEntrega" class="btn btn-lg btn-primary p-4"
                            (click)="setLocal(local)">{{local.origem}}</button>
                    </div>
                </div>
            </section>

            <section *ngIf="localSelecionado">
                <div class="row p-2">
                    <div class="col-12 d-grid gap-2">
                        <button class="btn btn-lg btn-primary p-4" [routerLink]="'/leitor/' + localSelecionado.id">Ler QR Code</button>
                        <button class="btn btn-lg btn-primary p-4" [routerLink]="'/entrega/' + localSelecionado.id">Entrega</button>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>