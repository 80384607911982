import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { registrosMokup } from '../shared/mokups';

import { ApiRetornoAluno, ApiRetornoOrigens, ApiRetornoRegistros, OrigemType, ProfileType, Registro, UsuarioAcessoType } from '../shared/tipos';

@Injectable({
  providedIn: 'root'
})
export class FilaCarroService {

  private readonly _apiEndpoint = environment.apiEndpint;
  private _local = new BehaviorSubject<OrigemType|null>(null);
  private _locais = new BehaviorSubject<OrigemType[]|null>(null);

  constructor(
    private http: HttpClient
  ) { }

  private loginSemAt(login: string): string {
    if (login.includes('@')) {
      return login.split('@')[0];
    }
    return login;
  }

  public propagarLocal(local: OrigemType): void {
    this._local.next(local);
  }

  get local(): BehaviorSubject<OrigemType|null> {
    return this._local;
  }

  public pegarOrigemFromId(id: number) {
    return this.http.get<{statusCode: number, data: OrigemType}>(this._apiEndpoint + '/origens/' + id);
  }

  public getAlunosFromTag(tag: string) {
    tag = encodeURIComponent(tag);
    return this.http.get<ApiRetornoAluno>(this._apiEndpoint + '/acesso/' + tag);
  }

  public colocarNaFila(alunos: UsuarioAcessoType[], solicitante: UsuarioAcessoType, agenteLogin: string, local: OrigemType) {
    agenteLogin = this.loginSemAt(agenteLogin);

    return this.http.post<any>(this._apiEndpoint + '/registros/retirar', {
      alunos, solicitante, agenteLogin, origem: local
    });
  }

  public getFilaPorLocal(localId: number) {
    // return of({statusCode: 200, data: registrosMokup});
    return this.http.get<ApiRetornoRegistros>(this._apiEndpoint + '/registros', {
      params: { status: 1, origemLeitura: localId, hoje: true }
    });
  }

  public entregarAlunos(registros: Registro[], agenteLogin: string, local: OrigemType) {
    agenteLogin = this.loginSemAt(agenteLogin);
    
    return this.http.put<any>(this._apiEndpoint + '/registros/entregar', {
      origem: local, registros, agenteLogin
    });
  }
  
  public cancelarEntrega(registros: Registro[], agenteLogin: string): Observable<any> {
    agenteLogin = this.loginSemAt(agenteLogin);

    return this.http.put<any>(this._apiEndpoint + '/registros/cancelar', { registros, agenteLogin });
  }

  public getGraphProfile() {
    return this.http.get<ProfileType>(environment.graphEndpoint);
  }

  public getOrigemList() {
    return this.http.get<ApiRetornoOrigens>(this._apiEndpoint + '/origens')
    .pipe(
      tap(retorno => {
        if (retorno.statusCode === 200) {
          this._locais.next(retorno.data);
        } else { 
          this._locais.next([]);
        }
      })
    );
  }


}
