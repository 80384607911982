<div class="wrapper">
  <div class="overlay" *ngIf="showLoading">
    <div class="loading-wrapper">
      <app-loading-internal [message]="message"></app-loading-internal>
    </div>
  </div>

  <div class="load-content" [class.blurred]="showLoading">
    <ng-content></ng-content>
  </div>
</div>
