<nav class="navbar navbar-expand-md bg-light">
    <div class="container-fluid">
        <a class="navbar-brand col-4 col-sm-2 col-md-1" href="#">
            <img class="img-fluid" [src]="imgs.bandNavBar">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navBarLinks"
            aria-controls="navBarLinks" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mt-3 mt-md-0" id="navBarLinks">
            <ul class="navbar-nav ms-md-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <button class="btn btn-outline-success" *ngIf="!loginDisplay" (click)="login()">Login</button>
                    <button class="btn btn-outline-danger" *ngIf="loginDisplay" (click)="logout()">Sair</button>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="container">
    <router-outlet *ngIf="!isIframe"></router-outlet>
</div>