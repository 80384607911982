import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { FilaCarroService } from './services/fila-carro.service';
import { LoadingScreenModule } from './loading-screen/loading-screen.module';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingScreenModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: 'a030e239-01da-4e71-8405-b9c96ea94b8a',
        authority: 'https://login.microsoftonline.com/f55ae9ff-e364-4d5d-a815-a73ea647db8b',
        redirectUri: environment.redirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      // guard de rota
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read'],
      }
    }, {
      // interceptor de rota, adiciona o token em cada requisição protegida abaixo
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ]),
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    FilaCarroService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
