import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'entrega',
    loadChildren: () => import('./entrega-module/entrega.module').then(m => m.EntregaModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'leitor',
    loadChildren: () => import('./leitor-qr-module/leitor-qrmodule.module').then(m => m.LeitorQRModule),
    canActivate: [MsalGuard]
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking': 'disabled',
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
