import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-full-screen',
  templateUrl: './loading-full-screen.component.html',
  styleUrls: ['./loading-full-screen.component.css']
})
export class LoadingFullScreenComponent implements OnInit {
  @Input() message: string = 'Aguarde...';
  constructor() { }

  ngOnInit() {
  }

}
