import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Overlay } from '@angular/cdk/overlay';
import { LoadingInternalComponent } from './loading-internal/loading-internal.component';
import { LoadingWrapperComponent } from './loading-wrapper/loading-wrapper.component';
import { LoadingFullScreenComponent } from './loading-full-screen/loading-full-screen.component';
import { LoadingFullScreenService } from './loading-full-screen.service';

@NgModule({
  declarations: [
    LoadingInternalComponent,
    LoadingWrapperComponent,
    LoadingFullScreenComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingWrapperComponent,
    LoadingInternalComponent
  ],
  entryComponents: [
    LoadingFullScreenComponent
  ],
  providers: [
    Overlay,
    LoadingFullScreenService
  ]
})
export class LoadingScreenModule { }
