import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilaCarroService } from '../services/fila-carro.service';
import { OrigemType } from '../shared/tipos';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private msalSubject$: Subscription;
  private inProgress$: Subscription;
  private subOrigemList$: Subscription;

  public localSelecionado: OrigemType|null = null;
  public locaisLeituraEntrega: OrigemType[] = [];

  public loginDisplay = false;
  public imgs = environment.imgs;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcasterService: MsalBroadcastService,
    private filaService: FilaCarroService
  ) {
    this.msalSubject$ = new Subscription();
    this.inProgress$ = new Subscription();
    this.subOrigemList$ = new Subscription();
  }

  ngOnInit(): void {
    // resultado do token ao login
    this.msalSubject$ = this.msalBroadcasterService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    )
    .subscribe((result: EventMessage) => {
      console.log(result); 
    });

    // para se foi possível autenticar
    this.inProgress$ = this.msalBroadcasterService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    });

    this.subOrigemList$ = this.filaService.getOrigemList()
    .subscribe(retorno => {
      if (retorno.statusCode === 200) this.locaisLeituraEntrega = retorno.data;
    });
  }

  ngOnDestroy(): void {
    this.msalSubject$.unsubscribe();
    this.inProgress$.unsubscribe();
    this.subOrigemList$.unsubscribe();
  }

  setLocal(local: OrigemType) {
    this.localSelecionado = local;
    this.filaService.propagarLocal(local);
  }

  trocarLocal() {
    this.localSelecionado = null;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  exibirBtnLeitura() {
    return !this.localSelecionado?.origem.includes('Transporte');
  }

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

}
