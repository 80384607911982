import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-internal',
  templateUrl: './loading-internal.component.html',
  styleUrls: ['./loading-internal.component.css']
})
export class LoadingInternalComponent implements OnInit {
  @Input() message: string = 'Aguarde...';

  constructor() { }

  ngOnInit() {
  }

}
