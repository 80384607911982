<div class="loading">
  <div class="h-50 hide d-inline-block"></div>
  <div class="dot-message d-inline-block">
    <div class="dots text-center">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <p class="loading-text text-center mb-0" *ngIf="message && message !== ''">{{message}}</p>
  </div>
  <div class="fundo"></div>
</div>
