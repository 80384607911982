import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingFullScreenComponent } from './loading-full-screen/loading-full-screen.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingFullScreenService {
  private overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay
  ) { }

  public show(message = '') {
    if (!this.overlayRef) {
      // em create é possível passar algumas configurações
      // de como abrir na tela
      this.overlayRef = this.overlay.create();
    }

    const loadingOverlayPortal = new ComponentPortal(LoadingFullScreenComponent);

    // para evitar ExpressionChangedAfterItHasBeenCheckedError [error]
    // vide em https://blog.angular-university.io/angular-debugging/
    setTimeout(() => {
      const component = this.overlayRef.attach(loadingOverlayPortal);
      component.instance.message = message;
    });
  }

  public hide() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
